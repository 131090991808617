// import db from "../utils/firebase";
import * as firebase from "firebase";
import moment from "moment";

// const db =   firebase
// .firestore()
// .collection("timeline")
// .doc(JSON.parse(localStorage.getItem("uid")));

export default {
  /**
   * this function will be fired when you first time run the app,
   * and it will fetch first 5 posts, here I retrieve them in descending order, until the last added post appears first.
   */
  postsFirstBatch: async function () {
    try {
      const data = await  firebase
      .firestore()
      .collection("timeline")
      .doc(JSON.parse(localStorage.getItem("uid")))      
        .collection("timelinePosts")
        .orderBy("time", "desc")
        // .startAfter( 1619209648, 783000000)
        // .startAfter({nanoseconds: 659000000,seconds: 1618672672})
        .limit(2)
        .get()
        // .then(snapshot => {
          // var posts = []
          // console.log( snapshot.docs[snapshot.docs.length - 1])
          // "2021-04-17T15:17:52.659Z"
        // });
      let posts = [];
      let lastKey = undefined;
      data.forEach((doc) => {
        let data = doc.data();
        posts.push({
username: data.username,
            userId: doc.data().userId,
            title: "post",
            profilePic: doc.data().userAvatar,
            image: doc.data().image,
            caption: doc.data().caption ? doc.data().caption : null,
            postId: doc.data().postId,
            timeStamp: doc.data().time,
            type: doc.data().type ? doc.data().type : null,
            video: doc.data().video ? doc.data().video : null,
            locName: doc.data().location ? doc.data().location.locationName : null,
            });
        lastKey =  doc.data().time;
        // var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
        // console.log(JSON.stringify(data.time))
        
        // console.log("doc:", doc) 
       console.log(doc.data().time)
      //  console.log(doc.data().time.toDate())
        // console.log(moment(data.time).format("LLLL") )
      //   console.log(moment(Number(doc.data().time)).format("LLLL") )
        // console.log(data)


        
      });
      

      return { posts, lastKey };
    } catch (e) {
      console.log(e);
    }
  },

  /**
   * this function will be fired each time the user click on 'More Posts' button,
   * it receive key of last post in previous batch, then fetch next 5 posts
   * starting after last fetched post.  
   */
  postsNextBatch: async (key) => {
    try {
      const data = await  firebase
      .firestore()
      .collection("timeline")
      .doc(JSON.parse(localStorage.getItem("uid")))      
      .collection("timelinePosts")
        .orderBy("time", "desc")
        .startAfter(key)
        .limit(5)
        .get();

      let posts = [];
      let lastKey = undefined;
      data.forEach((doc) => {
        let data = doc.data();
        posts.push({
username: data.username,
            userId: data.userId,
            title: "post",
            profilePic: data.userAvatar,
            image: data.image,
            caption: data.caption ? data.caption : null,
            postId: data.postId,
            timeStamp: data.time,
            type: data.type ? data.type : null,
            video: data.video ? data.video : null,
            locName: data.location ? data.location.locationName : null,
            });
            lastKey =  data.time;
          });
      return { posts, lastKey };
    } catch (e) {
      console.log(e);
    }
  }
};